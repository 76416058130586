// vim: et sw=2 ts=2
;(function (G) {
  G.cfui = G.cfui || {}

  // These breakpoint definitions are duplicated for scss in css/application/_settings.responsive.scss
  // If updating one, please also update the other.
  // TODO: Find a way to define these in a single place

  var breakpoints = {
    palm: 'screen and (max-width: 44.9375em)',
    lap: 'screen and (min-width: 45em) and (max-width: 63.9375em)',
    'lap-and-up': 'screen and (min-width: 45em)',
    portable: 'screen and (max-width: 63.9375em)',
    desk: 'screen and (min-width: 64em)',
    retina:
      '(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2dppx)',
  }

  G.cfui.mediaQueryBreakpoints = function () {
    return breakpoints
  }

  G.cfui.mediaQuery = function (name) {
    var match
    if (typeof breakpoints[name] === 'undefined') {
      console.error('Unknown breakpoint ' + name)
      match = window.matchMedia(name)
      return match ? match.matches : false
    }
    match = window.matchMedia(breakpoints[name])
    return match ? match.matches : false
  }
})(window)
