// vim: et sw=2 ts=2
;(function (root) {
  root.medshr = root.medshr || {}
  root.medshr.analytics = root.medshr.analytics || {}

  var branchData

  var getBranchData = function (callback) {
    if (typeof branchData === 'undefined') {
      if (typeof branch !== 'undefined') {
        branch.data(function (err, data) {
          callback(data)
        })
        return
      }
    }
    callback(branchData)
  }

  root.medshr.analytics.registerSuperProperties = function (
    properties,
    callback
  ) {
    getBranchData(function (branchData) {
      properties = properties || {}
      properties['platform'] = 'web'
      if (branchData) {
        if (!branchData.data_parsed && branchData.data) {
          branchData.data_parsed = JSON.parse(branchData.data)
        }
        if (branchData.data_parsed) {
          if (branchData.data_parsed['$marketing_title']) {
            properties['branch_link_title'] =
              branchData.data_parsed['$marketing_title']
          }
          if (branchData.data_parsed['~id']) {
            properties['branch_link_id'] = branchData.data_parsed['~id']
          }
        }
      }
      callback && callback(branchData)
    })
  }

  root.medshr.analytics.trackEvent = function (category, action, properties) {
    var args = {
      hitType: 'event',
      eventCategory: category,
      eventAction: action,
    }

    if (typeof properties === 'string' || typeof properties === 'number') {
      args.eventLabel = '' + properties
      properties = { label: '' + properties }
    }

    if (typeof properties === 'string') {
      args.eventLabel = properties
      properties = { label: properties }
    }

    if (typeof ga !== 'undefined') {
      ga('send', args)
    }

    var img = new Image()
    img.src =
      '/dscripts/log?action=' +
      escape(category + ' - ' + action) +
      '&properties=' +
      escape(JSON.stringify(properties))

    if (
      typeof console !== 'undefined' &&
      typeof console.log === 'function' &&
      /env-(dev|local|staging)/.test(document.documentElement.className)
    ) {
      console.log('GA:', args)
    }
  }

  root.medshr.analytics.trackPageView = function (path) {
    var args = {
      hitType: 'pageview',
      page: path,
    }
    if (typeof ga !== 'undefined') {
      ga('send', args)
    }
    if (
      typeof console !== 'undefined' &&
      typeof console.log === 'function' &&
      /env-(dev|local)/.test(document.documentElement.className)
    ) {
      console.log('GA:', args)
    }
  }

  var when = function (check, callback) {
    function timedCheck() {
      if (check()) {
        callback()
      } else {
        setTimeout(timedCheck, 250)
      }
    }
    timedCheck()
  }

  root.medshr.analytics.authed = function (user_id, properties) {
    user_id = '' + user_id
    root.medshr.analytics.registerSuperProperties(properties, function () {
      root.medshr.analytics.trackEvent('Auth', 'Login', user_id)
    })
  }

  root.medshr.analytics.registered = function (user_id, properties) {
    user_id = '' + user_id
    root.medshr.analytics.registerSuperProperties(properties, function () {
      root.medshr.analytics.trackEvent('Auth', 'Register', user_id)
    })
    if (typeof window.lintrk === 'function') {
      window.lintrk('track', { conversion_id: 3354748 })
    }
  }

  root.medshr.analytics.registrationComplete = function (user_id, properties) {
    user_id = '' + user_id
    root.medshr.analytics.registerSuperProperties(properties, function () {
      root.medshr.analytics.trackEvent('Setup profile', 'Complete', {
        'User ID': user_id,
      })
    })
  }

  root.medshr.analytics.logout = function () {
    root.medshr.analytics.trackEvent('Auth', 'Logout')
  }
})(window)
